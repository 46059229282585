import React from 'react'
import { StaticQuery, graphql } from 'gatsby'
import Img from 'gatsby-image'

import {
  Box,
  H1,
  ScrollArrow,
  FlexCol,
  FlexRow,
  NUDELogo,
} from '../../components'

import { FadeCol } from './style'

const BlogHeader = props => (
  <StaticQuery
    query={graphql`
      {
        wordpressPage(slug: { eq: "landing" }) {
          acf {
            banner_image {
              localFile {
                childImageSharp {
                  sizes(maxWidth: 1900, quality: 90) {
                    ...GatsbyImageSharpSizes_withWebp
                  }
                }
              }
            }
            banner_image_mobile {
              localFile {
                childImageSharp {
                  sizes(maxWidth: 1900, quality: 90) {
                    ...GatsbyImageSharpSizes_withWebp
                  }
                }
              }
            }
          }
        }
      }
    `}
    render={page => (
      <Box
        height="100%"
        id={process.env.NODE_ENV !== 'development' ? '/nude/' : '/'}
      >
        <Box display={['none', null, null, 'initial']}>
          <Img
            sizes={
              page.wordpressPage.acf.banner_image.localFile.childImageSharp
                .sizes
            }
            style={{
              position: 'absolute',
              top: 0,
              left: 0,
              width: '100%',
              height: '100vh',
              userSelect: 'none',
              pointerEvents: 'none',
            }}
          />
        </Box>
        <Box display={['initial', null, null, 'none']}>
          <Img
            sizes={
              page.wordpressPage.acf.banner_image_mobile.localFile
                .childImageSharp.sizes
            }
            style={{
              position: 'absolute',
              top: 0,
              left: 0,
              width: '100%',
              height: '100vh',
              userSelect: 'none',
              pointerEvents: 'none',
            }}
            imgStyle={{
              objectPosition: 'left center',
            }}
          />
        </Box>
        <FadeCol
          position="relative"
          zIndex={1}
          alignItems="center"
          pt={[128, 192, '33vmin']}
          px={32}
          height={['85%', null, null, '100%']}
          className={props.active ? '' : 'hidden'}
        >
          <FlexRow
            flexDirection={['column', 'row']}
            justifyContent="center"
            alignItems="center"
          >
            <Box
              width={(380 / 150) * 80 + 'px'}
              height={['48px', '80px']}
              mr={[0, 7]}
              mb={[4, 0]}
            >
              <NUDELogo />
            </Box>
            <H1 textAlign="center" color="text.reverse">
              Blog
            </H1>
          </FlexRow>
          <FlexCol alignItems="center" mt="auto" mb={6}>
            <ScrollArrow />
          </FlexCol>
        </FadeCol>
      </Box>
    )}
  />
)

export default BlogHeader
