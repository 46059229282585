import React from 'react'
import { Link } from 'gatsby'

import {
  Box,
  ContentStack,
  FlexCol,
  FlexGrid,
  Gallery,
  StickerStamp,
} from '../../../components'

const Post = props => (
  <Box
    height="100%"
    bg="bg.default"
    id={process.env.NODE_ENV !== 'development' ? '/nude/building' : '/building'}
  >
    <Box height="100%">
      <FlexGrid height="100%">
        {!props.flip && (
          <FlexCol
            width={[1 / 1, null, null, 1 / 2]}
            height={['85vh', null, null, '100%']}
            flex={1}
            is={Link}
            to={'/blog/' + props.post.slug + '/'}
          >
            <Gallery
              images={[props.post.featured_media]}
              active={props.active}
              id={'blog' + props.post.slug}
              expandable={false}
            />
          </FlexCol>
        )}
        <FlexCol
          justifyContent="center"
          alignItems="center"
          width={[1 / 1, null, null, 1 / 2]}
          order={[1, null, null, 0]}
        >
          <FlexCol
            mt={[64, null, null, 32]}
            mb={[64, null, null, 0]}
            mx={[32, null, null, 0]}
          >
            <FlexCol
              width={1 / 1}
              maxWidth={[480, null, null, 330]}
              ml={[0, null, null, -48]}
              mr={[-16, null, null, 0]}
              mb={[-16, null, null, -24]}
            >
              <StickerStamp title={`NUDE BLOG`} />
            </FlexCol>
            <ContentStack
              heading={props.post.title}
              body={props.post.excerpt}
              headingLink={'/blog/' + props.post.slug + '/'}
              bodyLink={'/blog/' + props.post.slug + '/'}
              buttonText={`Read more`}
              buttonLink={'/nude/blog/' + props.post.slug + '/'}
              noNewWindow={true}
            />
          </FlexCol>
        </FlexCol>
        {props.flip && (
          <FlexCol
            width={[1 / 1, null, null, 1 / 2]}
            height={['85vh', null, null, '100%']}
            flex={1}
            is={Link}
            to={'/blog/' + props.post.slug + '/'}
          >
            <Gallery
              images={[props.post.featured_media]}
              active={props.active}
              id={'blogFlip' + props.post.slug}
              expandable={false}
            />
          </FlexCol>
        )}
      </FlexGrid>
    </Box>
  </Box>
)

export default Post
