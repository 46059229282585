import React from 'react'
import { StaticQuery, graphql } from 'gatsby'

import {
  Layout,
  SplitScroll,
  ScrollWrapper,
  SEO,
  StaticHeader,
  MobileStaticHeader,
} from '../../components'

import BlogHeader from '../../views/blogHeader'
import Post from './_components/_post'
import Form from '../../views/form'

import { FontString } from '../../views/floorplans/components/fontString'

class BlogPage extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      index: 0,
      path: '',
      navigatePath: '',
      absorbRoute: false,
      showRight: true,
      mounted: false,
    }
  }

  componentDidMount = () => {
    let IE = false
    if (/MSIE 10/i.test(navigator.userAgent)) {
      // This is internet explorer 10
      IE = true
    }

    if (
      /MSIE 9/i.test(navigator.userAgent) ||
      /rv:11.0/i.test(navigator.userAgent)
    ) {
      // This is internet explorer 9 or 11
      IE = true
    }

    if (/Edge\/\d./i.test(navigator.userAgent)) {
      // This is Microsoft Edge
      IE = true
    }
    let w = Math.max(
      document.documentElement.clientWidth,
      window.innerWidth || 0
    )
    if (IE) {
      w = 0
    }
    if (w < 1024) {
      this.setState({ showRight: false })
    }
    this.setState({ mounted: true })
  }

  componentDidUpdate() {
    if (this.state.showRight) {
      if (
        this.props.location.pathname !== this.state.path &&
        !this.state.absorbRoute
      ) {
        this.setState({
          navigatePath: this.props.location.pathname,
          path: this.props.location.pathname,
        })
        //window.LassoCRM.tracker.track()
      }
      if (
        this.state.absorbRoute &&
        this.props.location.pathname !== this.state.path
      ) {
        this.setState({
          path: this.props.location.pathname,
          absorbRoute: false,
        })
        //window.LassoCRM.tracker.track()
      }
    }
  }

  updateIndex = index => {
    this.setState({ index: index })
  }

  updateRoute = route => {
    // if (route !== this.state.path) {
    //   this.setState({ absorbRoute: true }, () => {
    //     this.props.navigate(
    //       process.env.NODE_ENV !== 'development' ? '/nude' + route : route
    //     )
    //   })
    // }
  }
  render() {
    return (
      <StaticQuery
        query={graphql`
          {
            allWordpressPost(
              filter: { categories: { elemMatch: { name: { eq: "NUDE" } } } }
            ) {
              edges {
                node {
                  slug
                  featured_media {
                    source_url
                    localFile {
                      childImageSharp {
                        sizes(maxWidth: 1900, quality: 90) {
                          ...GatsbyImageSharpSizes_withWebp
                        }
                      }
                    }
                  }
                  categories {
                    name
                  }
                  title
                  excerpt
                }
              }
            }
          }
        `}
        render={data => {
          let contents = [<BlogHeader />]
          data.allWordpressPost.edges.forEach(post => {
            if (
              post.node.categories &&
              post.node.categories[0] &&
              post.node.categories[1] &&
              (post.node.categories[0].name === 'NUDE' ||
                post.node.categories[1].name === 'NUDE') &&
              post.node.featured_media &&
              post.node.featured_media.localFile &&
              contents.length < 25
            ) {
              contents.push(
                <Post
                  post={post.node}
                  flip={contents.length % 2 === 1 ? true : false}
                />
              )
            }
          })
          contents.push(<Form footer={true} />)
          contents.push(<div />)

          const reverseContents = contents.slice(0, 10000).reverse()

          return (
            <Layout>
              <style jsx>{`
                ${FontString};
              `}</style>
              <SEO
                title={'NUDE Blog'}
                description={`The latest news, happenings, and helpful tips and tricks from the NUDE team.`}
              />
              {this.state.mounted && (
                <>
                  <MobileStaticHeader />
                  <StaticHeader />
                  <ScrollWrapper
                    compressedOpener={true}
                    path={this.state.navigatePath}
                    content={contents}
                    updateIndex={this.updateIndex}
                    updateRoute={this.updateRoute}
                  >
                    <SplitScroll
                      compressedOpener={true}
                      content={contents}
                      reverseContents={reverseContents}
                      index={this.state.index}
                      showRight={this.state.showRight}
                    />
                  </ScrollWrapper>
                </>
              )}
            </Layout>
          )
        }}
      />
    )
  }
}

export default BlogPage
